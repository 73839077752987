(function() {
    'use strict';

    document.addEventListener("DOMContentLoaded", function () {
        const jsonLdGeneral = {
            "@context": "https://schema.org",
            "@type": "EducationalOrganization",
            "name": "RESTAUNI",
            "url": "https://www.restauni.com",
            "logo": "https://www.restauni.com/restauni.png",
            "description": "RESTAUNI es la plataforma educativa para dueños y gerentes de restaurantes, ofreciendo cursos con estrategias y consejos prácticos para optimizar la gestión gastronómica.",
            "brand": {
                "@type": "Brand",
                "name": "BDKSISTEMAS"
            },
            "offers": {
                "@type": "Offer",
                "url": "https://www.restauni.com",
                "priceCurrency": "USD",
                "price": "39.00",
                "availability": "https://schema.org/InStock",
                "category": "Educación"
            },
            "course": {
                "@type": "Course",
                "name": "Cursos para Dueños y Gerentes de Restaurantes",
                "description": "Cursos con estrategias y consejos prácticos para mejorar la gestión de restaurantes y llevar el negocio al siguiente nivel.",
                "provider": {
                    "@type": "Organization",
                    "name": "RESTAUNI",
                    "sameAs": "https://www.restauni.com"
                },
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.restauni.com",
                    "priceCurrency": "USD",
                    "price": "39.00",
                    "availability": "https://schema.org/InStock",
                    "category": "Educación"
                },
                "hasCourseInstance": {
                    "@type": "CourseInstance",
                    "name": "Curso de Estrategias para Dueños de Restaurantes",
                    "description": "Este curso ofrece lecciones sobre optimización de la gestión de restaurantes para aumentar la eficiencia y la satisfacción del cliente.",
                    "courseMode": "Online",
                    "courseWorkload": "Aproximadamente 5-10 minutos por lección."
                }
            }
        };

        // Crear y agregar el script JSON-LD al DOM
        const script = document.createElement("script");
        script.type = "application/ld+json";
        script.text = JSON.stringify(jsonLdGeneral);
        document.head.appendChild(script);
    });

})();
